<div class="container">
  <div [ngClass]="{'aboutus': translate.is_arabic, 'aboutusEn': !translate.is_arabic}">
    <div class="row flex-wrap">
      <div class="col-12">
        <div class="header"><p>{{translate.data.aboutUS.header.title}}</p></div>
      </div>
    </div>
    <div class="body">
      <div class="row flex-wrap">
        <div class="col-lg-6 col-12 mb-5">
          <div class="contant" *ngIf="data">
            <h2>{{data[translate.data.requestdData.genTite]}}</h2>
            <p>{{data[translate.data.requestdData.genDesc]}}</p>
          </div>
        </div>
        <div class="col-6 d-lg-inline d-none">
          <div class="img"><img src="../../../assets/aboutus/Layer 2.svg" alt="logo"></div>
        </div>
        <div class="col-12 d-flex flex-wrap justify-content-evenly my-lg-5 my-2">
          <div class="cards">
            <img src="../../../assets/aboutus/icons/Group 10435.svg" alt="">
            <p class="fw-bold">{{translate.data.aboutUS.icons.location.title}}</p>
            <p class="edit-resp">{{companyData?.address}}</p>
            <!-- <p>{{translate.data.aboutUS.icons.location.p2}}</p> -->
          </div>
          <div class="cards">
            <img src="../../../assets/aboutus/icons/Group 10436.svg" alt="">
            <p class="fw-bold">{{translate.data.aboutUS.icons.email.title}}</p>
            <p>{{companyData?.email}}</p>
            <p>{{companyData?.email}}</p>
          </div>
          <div class="cards ">
            <img src="../../../assets/aboutus/icons/Group 10437.svg" alt="">
            <p class="fw-bold">{{translate.data.aboutUS.icons.phone.title}}</p>
            <p>{{companyData?.phone}}</p>
            <p>{{companyData?.fax}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>


<router-outlet></router-outlet>
