import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService} from '../../pages/pages.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  data:any;
  companyData:any;

  constructor(private spinner: NgxSpinnerService ,private router: Router , public translate: TranslationService,
    private route: ActivatedRoute,private pagesService: PagesService) { }

  ngOnInit(): void {
      this.spinner.show();
      // setTimeout(() => {
      //   this.spinner.hide();
      // }, 2000);

      this.pagesService.getAbout().subscribe(
        (response: any) => {
          this.data = response.data[0];
          console.log("caravan",this.data);
        },
        (error) => {
          console.log(error);
        }
      )

      this.pagesService.getCompanyDetails().subscribe(
        (response: any) => {
          this.companyData = response.data[0];
          console.log("companyData",this.companyData);
          this.spinner.hide();
        },
        (error) => {
          console.log(error);
        }
      )
  }

}
